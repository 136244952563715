import React, { useState, useEffect } from "react"
import Layout from "./../components/Layout"
import PolitiqueDeConfidentialite from "./../nav/PDC"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"

const PDCPage = () => {
  const [user, setUser] = useState(undefined)
  useEffect(() => {
    getUser(setUser)
  }, [])

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Politique de confidentialité</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-25 ans. Sur cette page,
          tu trouveras la Politique de confidentialité de WYNIT."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,politique"
        />
        <link
          rel="canonical"
          href="https://wynit.com/politique-de-confidentialite"
        />
      </Helmet>
      <PolitiqueDeConfidentialite />
    </Layout>
  )
}

export default PDCPage
