import React from "react"
import { Wrapper, SectionUnderline } from "./PDC.module.css"
import { OrangeTitle } from "./../../css/Titre.module.css"
import {
  PaddingLeft30,
  PaddingTop20,
  PaddingTop30,
  PaddingTop50,
} from "./../../css/Padding.module.css"
import { classNames } from "./../../helpers/classNames"

const PolitiqueDeConfidentiale = () => {
  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>
        Politique de Confidentialité et de Protection des Données Personnelles
      </h1>
      <div className={PaddingTop50}>
        <h3>PRÉAMBULE</h3>
        <p className={PaddingTop30}>
          La société WYNIT a pour objet de commercialiser auprès des étudiants,
          par l’intermédiaire de son site et de son application « wynit » des
          cartes virtuelles sous forme d’abonnement leur permettant de
          bénéficier d’offres de réduction et d’offres promotionnelles auprès de
          différents établissements partenaires.
        </p>
        <p className={PaddingTop20}>
          L’utilisation de ces services nécessite la collecte de données
          personnelles. Cette Politique de confidentialité décrit comment la
          société WYNIT traite les informations personnelles collectées par le
          biais du site et de l’application « wynit ».
        </p>
        <p className={PaddingTop20}>
          En utilisant les services offerts par le site et l’application « wynit
          », vous reconnaissez avoir pris connaissance de notre Politique de
          Confidentialité et de Protection des Données Personnelles (sur cette
          page) et consentez à ce que nous collections et utilisions ces données
          pour les usages internes ci-après décrits.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 1 – RESPONSABLE DU TRAITEMENT</h3>
        <p className={PaddingTop30}>
          Le responsable du traitement des données est la société WYNIT, SAS au
          capital de euros en cours de constitution et dont le siège social est
          sis 22 Domaine Saint Christophe 13011 MARSEILLE.
        </p>
        <p className={PaddingTop20}>
          Les coordonnées de contact sont les suivantes:
        </p>
        <p className={PaddingLeft30}>
          - Adresse postale: 22 Domaine Saint Christophe 13011 MARSEILLE
        </p>
        <p className={PaddingLeft30}>
          - Courrier électronique: contact@wynit.fr
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 2 – DONNEES PERSONNELLES RECUEILLIES</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          2.1 – Lors d’une utilisation de nos services
        </p>
        <p className={PaddingTop20}>
          Lorsque vous utilisez nos services, nous recueillons les
          renseignements personnels que vous nous fournissez notamment lors de
          la création de votre compte, tels que :
        </p>
        <p className={PaddingLeft30}>- Votre nom</p>
        <p className={PaddingLeft30}>- Votre prénom</p>
        <p className={PaddingLeft30}>- Votre âge</p>
        <p className={PaddingLeft30}>
          - Votre adresse et votre pays de résidence
        </p>
        <p className={PaddingLeft30}>- Votre numéro de téléphone</p>
        <p className={PaddingLeft30}>- Votre adresse e-mail</p>
        <p style={{ color: "red" }} className={PaddingTop30}>
          Nous sommes amenés à collecter votre âge et/ou votre date de naissance
          afin de nous assurer que vous disposez de l’âge requis pour utiliser
          nos services ou afin d’obtenir la transmission d’une autorisation
          parentale pour les mineurs.
        </p>
        <p className={PaddingTop30}>
          Certaines de ces données doivent être obligatoirement renseignées pour
          permettre l’utilisation de nos services. Nous recueillons également
          les données que vous nous fournissez dans le cadre des commentaires et
          avis que vous êtes susceptible de laisser suite à l’utilisation d’une
          offre auprès d’un établissement partenaire. Nous recueillons également
          des données relatives à votre utilisation de notre Site (telles que le
          nombre d’offres utilisés, votre date d’inscription, le nombre
          d’évaluations laissées, etc.). S’agissant de vos données bancaires,
          nous vous informons que nous ne stockons aucune coordonnée bancaire
          car le paiement est pris en charge par des tiers, à savoir Stripe,
          Paypal. La responsabilité du chiffrement, du stockage et de la
          sécurité de vos coordonnées bancaire sont sous la responsabilité de ce
          tiers. En ce qui concerne ces fournisseurs, nous vous recommandons de
          lire attentivement leurs politiques de confidentialité pour que vous
          puissiez comprendre la manière dont ils traiteront vos données
          personnelles. Lorsque vous naviguez sur notre site, nous recevons
          également automatiquement l’adresse de protocole Internet (adresse IP)
          de votre ordinateur, qui nous permet d’obtenir plus de détails au
          sujet du navigateur et du système d’exploitation que vous utilisez. En
          créant un compte sur notre Site, vous avez la possibilité d’accepter
          de recevoir une lettre d’information qui vous tiendra au courant des
          évènements à venir et des nouveautés mises en place. Vous pourrez bien
          évidemment vous désinscrire à tout moment en cliquant sur le lien
          présent à la fin de nos emails.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          2.2– Lors d’une visite sur notre Site
        </p>
        <p className={PaddingTop20}>
          Lors de chacune de vos visites sur notre Site, nous sommes
          susceptibles de recueillir, conformément à la législation applicable,
          des informations relatives aux appareils sur lesquels vous utilisez
          nos services ou aux réseaux depuis lesquels vous accédez à nos
          services, tels que notamment vos adresses IP, données de connexion,
          types et versions de navigateurs internet utilisés, types et versions
          des plugins de votre navigateur, systèmes et plateformes
          d’exploitation, données concernant votre parcours de navigation sur
          notre Site, notamment votre parcours sur les différentes pages URL de
          notre Site, le contenu auquel vous accédez ou que vous consultez, les
          termes de recherches utilisés, les erreurs de téléchargement, la durée
          de consultation de certaines pages, l’identifiant publicitaire de
          votre appareil, les interactions avec la page ainsi que tout numéro du
          téléphone utilisé pour nous contacter. Parmi les technologies
          utilisées pour recueillir ces informations, nous avons notamment
          recours aux cookies (pour en savoir plus à ce sujet, veuillez vous
          référer à l’article 7 ci-après). Lorsque vouslaissez un commentaire
          sur notre site web, les donnéesinscrites dansle formulaire de
          commentaire, mais aussi votre adresse IP et l’agent utilisateur de
          votre navigateur sont collectés pour nous aider à la détection des
          commentaires indésirables. Si vous y avez consenti, nous utilisons
          également vos données pour communiquer avec vous afin de vous suggérer
          des offres susceptibles de vous intéresser et de vous informer des
          nouveautés. Vous pouvez également recevoir une newsletter sur votre
          adresse électronique, vous informant de l’actualité de notre Site.
          Toutes les lettres d’information qui vous seront envoyées par courrier
          électronique comportent un lien qui vous permettra de mettre fin à
          l’envoi de ces courriers électroniques.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 3 – FINALITE DE LA COLLECTE DES DONNES PERSONNELLES</h3>
        <p className={PaddingTop30}>
          En fonction de leur finalité, ces données sont collectées soit parce
          que vous acceptez de nous les communiquer, soit parce qu’elles sont
          nécessaires à l’exécution de nos prestations, soit également parce
          qu’elles répondent à une obligation légale. Nous utilisons les données
          que nous recueillons afin de:
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.1</span> Exécution de notre
          prestation de services afin de vous fournir le ou les services
          demandés: Ce traitement est nécessaire à l’exécution de nos
          obligations contractuelles respectives.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.2</span> Envoi d’informations
          concernant nos services (comme par exemple, les confirmations de
          réservation des offres) par e-mail, SMS ou tout autre moyen de
          communication: Envoi d’informations concernant nos services (comme par
          exemple, les confirmations de réservation des offres) par e-mail, SMS
          ou tout autre moyen de communication.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.3</span> Envoi d’informations
          concernant nos services (comme par exemple, les confirmations de
          réservation des offres) par e-mail, SMS ou tout autre moyen de
          communication: Ce traitement est nécessaire à l’exécution de nos
          obligations contractuelles respectives.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.4</span> Vous permettre de
          personnaliser votre profil sur nos Plateformes: Ce traitement est
          réalisé sur la base de votre consentement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.5</span> Vous permettre de
          communiquer et d’échanger avec les autres utilisateurs du Site: Ce
          traitement est nécessaire à l’exécution de nos obligations
          contractuelles respectives et/ou réalisé avec votre consentement.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.6</span> Vous donner accès et
          vous permettre de communiquer avec notre service client: Ce traitement
          est (i) nécessaire à l’exécution de nos obligations contractuelles
          respectives, (ii) réalisé avec votre consentement et/ou (iii)
          nécessaire à la constatation, l’exercice ou la défense d’un droit en
          justice.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.7</span> Nous assurer du
          respect de (i) la législation applicable, (ii) nos conditions
          générales d’utilisation, et (iii) notre politique de confidentialité.
          En cas de manquements de votre part, nous pourrons être amenés à
          suspendre votre compte sur notre Site: Ce traitement est (i)
          nécessaire à l’exécution de nos obligations contractuelles
          respectives, (ii) réalisé avec votre consentement et/ou (iii)
          nécessaire à la constatation, l’exercice ou la défense d’un droit en
          justice.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.8</span> Vous envoyer,
          conformément aux dispositions légales applicables et avec votre accord
          lorsque la législation l’exige, des messages marketing, publicitaires
          et promotionnels et des informations relatives à l’utilisation de nos
          services, ou vous suggérer et vous conseiller des services
          susceptibles de vous intéresser: Ce traitement est fondé sur votre
          consentement et/ou notre intérêt légitime (vous fournir des
          informations pertinentes).
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.9</span> Afin de vous informer
          des modifications apportées à nos services: Ce traitement est
          nécessaire à (i) l’exécution de nos obligations contractuelles
          respectives, (ii) au respect de nos obligations légales et/ou (iii) à
          la constatation, l’exercice ou la défense d’un droit en justice.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.10</span> Vérifier les données
          collectées lors de votre inscription sur le Site (âge, document
          d’identité....) et lutte contre la fraude: Ce traitement est (i) fondé
          sur votre consentement, (ii) nécessaire au respect de nos obligations
          légales et/ou (iii) nécessaire à la constatation, l’exercice ou la
          défense d’un droit en justice.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.11</span> Gérer notre Site et
          effectuer des opérations techniques internes dans le cadre de
          résolution de problèmes, analyse de données, de tests, recherches,
          analyses, études et sondages: Ce traitement est fondé sur notre
          intérêt légitime (assurer la sécurité de notre Site et en améliorer
          ses caractéristiques).
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>3.12</span> Evaluer l’efficacité
          des messages publicitaires que nous adressons et les adapter à nos
          utilisateurs: Ce traitement est fondé (i) sur notre intérêt légitime
          (mesurer et optimiser l’efficacité de nos campagnes) et/ou (ii) sur
          votre consentement.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 4 – DUREE DE CONSERVATION DE VOS DONNEES PERSONNELLES</h3>
        <p className={PaddingTop30}>
          Conformément à la législation et réglementation en vigueur, vos
          données sont conservées pour la durée strictement nécessaire aux
          finalités poursuivies telles que décrites ci-dessus.
        </p>
        <p>Ainsi, pour les finalités suivantes:</p>
        <p className={PaddingLeft30}>
          - Fourniture du service, collecte des paiements, facturation,
          personnalisation du service et du profil
        </p>
        <p className={PaddingLeft30}>- Gestion de la relation client</p>
        <p className={PaddingLeft30}>- Informations et newsletters</p>
        <p className={PaddingLeft30}>- Contact service client</p>
        <p className={PaddingTop20}>
          La durée de conservation des données des clients est celle de la
          relation commerciale augmentée de 3 ans à des fins de prospection
          commerciale.
        </p>
        <p className={PaddingTop20}>En outre, pour les finalités suivantes:</p>
        <p className={PaddingLeft30}>
          - Amélioration et personnalisation de nos services : la durée de
          conservation des cookies est de 13 mois maximum, et la durée de
          conservation des données des clients est celle de la relation
          commerciale augmentée de 3 ans à des fins de prospection commerciale.
        </p>
        <p className={PaddingLeft30}>
          - Lutte contre la Fraude : notre site accepte les paiements effectués
          via Stripe, Paypal et . Pour lutter contre les paiements frauduleux,
          ces partenaires conservent les données relatives aux transactions
          effectuées pendant une certaine durée. Pour plus de détails, veuillez
          prendre connaissance de leur politique de confidentialité.
        </p>
        <p className={PaddingTop20}>
          Enfin, pour satisfaire à nos obligations légales, par exemple dans le
          cadre de la constatation, l'exercice ou la défense de droits en
          justice ou bien pour répondre à des obligations légales notamment
          comptables, fiscales ou d’archivage, nous pouvons être amenés à
          conserver vos données plus longtemps que les durées mentionnées
          ci-dessous. Dans ce cas, nous réaliserons un archivage de vos données
          avec accès restreint au seul responsable du traitement.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 5 – DESTINATAIRE DES DONNEES COLLECTEES</h3>
        <p className={PaddingTop30}>
          Les données collectées sont principalement réservées à un usage
          purement interne. Nous ne procédons à aucune cession ni
          commercialisation de vos données. Toutefois, pour fournir nos
          services, nous pouvons cependant être amenés à communiquer certaines
          de vos données à nos prestataires. En outre, les partenaires de nos
          Sites internet peuvent également être amenés à collecter des données
          vous concernant et pour lesquels ils sont eux-mêmes responsables de
          traitement. Ainsi, nous acceptons les paiements via Stripe, Paypal .
          Lors du traitement des paiements, certaines données seront transmises
          à Stripe, Paypal incluant les informations obligatoires pour procéder
          à l’exécution du paiement, tel que le montant total ou les
          informations de facturation. Pour plus de détails, veuillez prendre
          connaissance de leur politique de confidentialité.
        </p>
        <p className={PaddingTop20}>
          Les données collectées pourront également être communiquées aux
          différents établissements partenaires afin de leur permettre notamment
          d’améliorer le contenu des offres et de les adapter à la demande. Vous
          avez la possibilité de vous opposer à la transmission de vos données
          aux Etablissement Partenaire en envoyant un courrier à SAS WYNIT 22
          Domaine Saint Christophe 13011 MARSEILLE ou un courrier électronique à
          l’adresse suivante : contact@wynit.fr.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 6 – EXERCICE DE VOS DROITS</h3>
        <p className={PaddingTop30}>
          Conformément à la Loi Informatique et Libertés et la réglementation
          européenne sur la protection des données personnelles, vous disposez
          de droits sur les données vous concernant:
        </p>
        <p className={PaddingLeft30}>
          - Droit d’accès : Vous avez le droit de nous demander confirmation que
          vos données font bien l’objet d’un traitement et obtenir les
          informations relatives à ce traitement (finalités, destinataires
          etc…).
        </p>
        <p className={PaddingLeft30}>
          - Droit de rectification et d’effacement des données : Vous pouvez
          demander la rectification de vos données lorsqu’elles sont inexactes
          ou incomplètes. Vous pouvez également demander l’effacement de vos
          données sous réserve de nos obligations légales de conservation.
        </p>
        <p className={PaddingLeft30}>
          - Droit à la limitation du traitement : vous pouvez demander la
          limitation du traitement de vos Données, sous réserve du respect des
          obligations légales.
        </p>
        <p className={PaddingLeft30}>
          - Droit d'opposition : vous pouvez vous opposer à tout moment au
          traitement de vos données, sauf en cas de motifs légitimes et
          impérieux de notre part. Vous pouvez également vous opposer au
          traitement fait à des fins de prospection.
        </p>
        <p className={PaddingLeft30}>
          - Droit à la portabilité : si les exigences légales sont remplies,
          vous pouvez demander à recevoir vos données dans un format structuré,
          couramment utilisé, lisible par machine et interopérable, et de les
          transmettre à un autre responsable du traitement sans que nous y
          fassions obstacle.
        </p>
        <p className={PaddingLeft30}>
          - Droit de ne pasfaire l'objet d'une décision fondée sur un traitement
          automatisé : vous avez le droit de ne pas faire l’objet d’une décision
          fondée exclusivement sur un traitement automatisé produisant des
          effets juridiques vous concernant ou vous affectant, sauf lorsque
          cette décision est nécessaire à la conclusion ou à l’exécution d’un
          contrat, ou est autorisée légalement. Nous vous informons que nous ne
          prenons aucune décision automatisée.
        </p>
        <p className={PaddingLeft30}>
          - Réclamations : vous avez le droit d’introduire une réclamation
          auprès d’une autorité de contrôle.
        </p>
        <p className={PaddingLeft30}>
          - Directives en cas de décès : vous avez le droit de donner des
          directives pour le sort de vos données personnelles en cas de décès.
        </p>
        <p className={PaddingTop20}>
          Vous trouverez ici une information concernant l’exercice de ces droits
          : https://www.cnil.fr/fr/comprendre-vos-droits
        </p>
        <p className={PaddingTop20}>
          Pour exercer vos droits, il vous suffit de contacter le responsable du
          traitement:
        </p>
        <p className={PaddingLeft30}>
          - Soit par lettre recommandée avec demande d’avis de réception à
          l’adresse suivante: WYNIT 22 Domaine Saint Christophe 13011 MARSEILLE
        </p>
        <p className={PaddingLeft30}>
          - Soit par courriel à l’adresse de contact suivante: contact@wynit.fr
        </p>
        <p className={PaddingTop20}>
          Conformément à la réglementation en vigueur, votre demande doit être
          signée et accompagnée de la photocopie d'un titre d'identité portant
          votre signature et préciser l'adresse à laquelle doit vous parvenir la
          réponse. Une réponse vous sera alors adressée dans un délai d’1 mois
          maximum à compter de la réception de la demande.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 7 – LIENS HYPERTEXTE ET COOKIES</h3>
        <p className={PaddingTop30}>
          Notre Site contient un certain nombre de liens hypertextes vers
          d'autres sites. Cependant, nous n'avons pas la possibilité de vérifier
          le contenu des sites ainsi visités, et nous n'assumons en conséquence
          aucune responsabilité de ce fait.
        </p>
        <p className={PaddingTop20}>
          La navigation sur notre Site est susceptible de provoquer
          l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un
          cookie est un fichier de petite taille, qui ne permet pas
          l'identification de l'utilisateur, mais qui enregistre
          desinformationsrelatives à la navigation d'un ordinateur sur un site.
          Les données ainsi obtenues visent à faciliter la navigation ultérieure
          sur le site, et ont également vocation à permettre diverses mesures de
          fréquentation.
        </p>
        <p className={PaddingTop20}>
          Vous avez la possibilité d'accepter ou de refuser les cookies en
          modifiant les paramètres de votre navigateur. Cependant, vous risquez
          de perdre certaines fonctions interactives du site si les cookies sont
          désactivés.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 8 – SÉCURITÉ</h3>
        <p className={PaddingTop30}>
          Pour protéger vos données personnelles, nous prenons des précautions
          raisonnables et suivons les meilleures pratiques de l’industrie pour
          nous assurer qu’elles ne soient pas perdues, détournées, consultées,
          divulguées, modifiées ou détruites de manière inappropriée.
        </p>
        <p className={PaddingTop20}>
          Notre site est mis à jour régulièrement et consulté quotidiennement,
          il fait l’objet d’une sécurité avancée. Si vos données devaient être
          compromises de quelques manières que ce soit, nous sommes tenus d’en
          informer la CNIL dans les 72h. Vous serez également tenu au courant de
          cet éventuel incident et des mesures correctives mise en œuvre.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>
          ARTICLE 9 –MODIFICATIONS APPORTÉES À LA PRÉSENTE POLITIQUE DE
          CONFIDENTIALITÉ
        </h3>
        <p className={PaddingTop30}>
          Nous nous réservons le droit de modifier la présente politique de
          confidentialité à tout moment. Nous vous invitons donc à la consulter
          fréquemment. Les changements et les clarifications prendront effet
          immédiatement après leur publication sur le site web. Si nous
          apportons des changements au contenu de cette politique, nous vous
          aviserons de sa mise à jour.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 10 – QUESTIONS ET COORDONNEES</h3>
        <p className={PaddingTop30}>
          Pour toutes questions relatives à la présente politique de
          confidentialité, nous vous invitons à contacter la société WYNIT soit
          par courriel contact@wynit.fr ou par courrier à l’adresse SAS WYNIT 22
          Domaine Saint Christophe 13011 MARSEILLE.
        </p>
      </div>
    </div>
  )
}

export default PolitiqueDeConfidentiale
